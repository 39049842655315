.mailSubmitStatusMsg {
    display: flex;

    &.success {
        background-color: #7bb661;
    }

    &.failure {
        background-color: #ea3f37;
    }
    
    .statusMsgText {
        margin: 1rem auto;
        font-size: 24px;
        color: white;
    }

    .closeButton {
        margin: 1rem 2rem;
        font-size: 24px;
        color: white;
        cursor: pointer;
    }
}