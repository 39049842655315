.heading {
    padding: 2rem;
    display: flex;
    background-color: white;

    .logo {
        display: flex;
        cursor: pointer;

        img {
            height: fit-content;
            margin: auto;
        }
    }

    .quickDetails {
        display: flex;
        margin-left: auto;

        .desktopContainer {
            display: flex;

            .location {
                margin-right: 4rem;

                a {
                    display: contents;

                    .headingDetails {
                        p {
                            color: black;
                        }
                    }
                }
            }
    
            .phoneNumber {
                margin-right: 2rem;
                
                .numberEntry {
                    color: red;
                    font-weight: bold;
                }
            }
    
            .location, .phoneNumber {
                display: flex;
    
                svg {
                    margin: auto;
                    margin-right: 1rem;
                    height: 2.5rem;
                    width: auto;
                    fill: #9b9b9b;
                }
    
                .headingDetails {
                    margin: auto;
    
                    p {
                        margin: 0;
                        line-height: 1.5rem;
                    }
                }
            }
        }

        .mobileContainer {            
            .number {
                .numberEntry {
                    color: red;
                    font-weight: bold;
                }
            }
        }
    }
}