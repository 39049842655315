.contentContainer {
    background-color: #fbfbfb;

    .whoAreWe,
    .taxiContainer,
    .PreDateTaxiContainer,
    .deliveryServiceContainer, 
    .toursContainer,
    .fleetContainer,
    .mailingAddressContainer,
    .officeNumbersContainer,
    .directContactLinesContainer,
    .bookingContainer,
    .bookingSpecialRequestContainer,
    .commentsContainer,
    .buttonContainer {
        padding: 2rem;

        .title {
            font-family: "Roboto Condensed","Roboto Condensed-fb",Helvetica,Arial,sans-serif;
            font-size: 2.5rem;
            color: #191919;
            font-weight: bold;

            .note {
                font-size: 1rem;
                color: grey;
                margin-left: 4px;
                margin-top: 0.5rem;

                span {
                    color: red;
                    font-weight: bold;
                }
            }
        }

        .content {
            margin-top: 1rem;
            line-height: 3rem;
            font-size: 24px;
            color: #5d5d5d;

            p {
                .tabbedList {
                    margin-left: 40px;
                }

                .contentLink {
                    color: #5d5d5d;
                    text-decoration: underline;
                    cursor: pointer;

                    &:hover {
                        color: #de302f;
                    }
                }
            
                &.rates {
                    line-height: 3rem;
                    font-size: 1.5rem;
                    margin-top: 1rem;
                    margin-bottom: 1rem;
                }
            }

            &.fleetDisplay {
                display: flex;

                .leftPanel { width: 60%; }
                .rightPanel {
                    margin: auto;
                    display: block;
                    width: 40%;
                    text-align: center;

                    img {
                        margin: 1rem;
                        height: 200px;
                    }
                }
            }
            
            &.mobileFleetDisplay {                
                .rightPanel {
                    margin: auto;
                    display: block;
                    text-align: center;

                    img {
                        margin: 1rem;
                        height: 100px;
                    }
                }
            }

            .address, .phoneNumbers, .directLines {
                line-height: 3rem;

                a {
                    color: #5d5d5d;

                    &:hover {
                        color: #de302f;
                    }
                }
            }

            .formEntry {
                display: inline-grid;
                width: calc(50% - 2rem);
                margin: 1rem;

                label {
                    span {
                        color: red;
                        font-weight: bold;
                    
                        &.extraNote {
                            font-weight: bold;
                            font-size: 1.25rem;
                            color: grey;
                            font-family: "Roboto Condensed", "Roboto Condensed-fb", Helvetica, Arial, sans-serif;
                        }
                    }
                }

                .react-datepicker__input-container {
                    display: grid;
                }

                input {
                    line-height: 3rem;
                    font-size: 24px;
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
            }

            .checkBoxEntry {
                .container {
                    display: block;
                    position: relative;
                    padding-left: 35px;
                    cursor: pointer;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    line-height: normal;
                    margin-top: 1rem;

                    &.disabled {
                        color: #a9a9a9;
                        font-style: italic;
                    }
                }
                  
                  /* Hide the browser's default checkbox */
                .container input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                }
                  
                  /* Create a custom checkbox */
                .checkmark {
                    position: absolute;
                    top: 4px;
                    left: 0;
                    height: 24px;
                    width: 24px;
                    background-color: #eee;
                }
                  
                  /* On mouse-over, add a grey background color */
                .container:hover input ~ .checkmark {
                    background-color: #ccc;
                }
                  
                  /* When the checkbox is checked, add a blue background */
                .container input:checked ~ .checkmark {
                    background-color: #2196F3;
                }
                  
                  /* Create the checkmark/indicator (hidden when not checked) */
                .checkmark:after {
                    content: "";
                    position: absolute;
                    display: none;
                }
                  
                  /* Show the checkmark when checked */
                .container input:checked ~ .checkmark:after {
                    display: block;
                }
                  
                  /* Style the checkmark/indicator */
                .container .checkmark:after {
                    left: 9px;
                    top: 4px;
                    width: 4px;
                    height: 10px;
                    border: solid white;
                    border-width: 0 3px 3px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }

            textarea {
                width: calc(100% - 2rem);
                height: 10rem;
                line-height: 3rem;
                font-size: 24px;
                padding: 1rem;
            }

            &.mobileContent {
                line-height: 2rem;
                font-size: 20px;

                .formEntry {
                    width: calc(100% - 2rem);
                }

                textarea {
                    font-size: 20px;
                }
            }
        }

        .bookingsSubmitButton {
            line-height: 3rem;
            font-size: 24px;
            padding: 0 1rem;
            width: 300px;
            height: 80px;
            background: dodgerblue;
            border-radius: 6px;
            font-family: sans-serif;
            color: white;
            border: none;

            &:hover {
                background-color: #1568b9;
                cursor: pointer;
            }

            &.blocked {
                background-color: #5d5d5d;
                cursor: not-allowed;
            }
        }
    }

    .buttonContainer {
        padding: 0 2rem 2rem 2rem;

        &.mobileButton {
            text-align: center;
        }
    }
}