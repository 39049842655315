.banner {
    position: relative;

    .imageContainer {
        position: relative;
        overflow: hidden;
    
        .bannerShade {
            background-color: rgb(0 0 0 / 65%);
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }

    .textContainer {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        color: white;
        display: flex;

        .pageName {
            margin: auto;
            font-size: 2rem;
            border-bottom: 2.5px solid white;
            padding: 1rem;
        }
    }
}
