.containers {
    padding-bottom: 2rem;

    .photoContainer {
        max-height: 700px;
        position: relative;

        & > img {
            height: 700px;
            width: 100%;
        }

        .imageOverlay {
            position: absolute;
            height: 700px;
            width: 100%;
            top: 0;
            background-color: rgba(0,0,0,0.5);
            color: white;

            .messageContainer {
                width: 90%;
                height: 100%;
                margin: auto;
                position: relative;
                text-align: center;
                display: grid;
                align-items: center;

                .messageBlob {
                    width: fit-content;
                    margin: auto;
                    padding: 1rem;
                    background-color: rgba(0,0,0,0.65);

                    .title {
                        font-size: 48px;
                        font-weight: bold;
                        margin: 1rem;
                    }

                    .message {
                        font-size: 16px;
                        margin: 1rem;
                    }

                    .buttons {
                        margin: 1rem;
                        display: inline-flex;

                        .bookNow {
                            // margin-right: 0.5rem;
                            // padding: 0.5rem;

                            margin: 0.4rem;
                            display: block;
                            padding-left: 1.5rem;
                            padding-right: 1.5rem;
                            border: solid 1px #de302f;
                            border-radius: 5px;
                            color: white;
                            background: #de302f;
                            cursor: pointer;

                            p {
                                margin-top: 0.5rem;
                                margin-bottom: 0.5rem;
                            }
                        }

                        .appStore {
                            margin-right: 0.5rem;
                            cursor: pointer;
                        }

                        .googleStore {
                            cursor: pointer;
                        }

                        .appStore, .googleStore {
                            img {
                                width: 100%;
                            }
                        }

                        &.mobileView {
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }

    .recordingNote {
        margin-top: 2rem;
        text-align: center;
        font-weight: bold;
        font-family: "Roboto Condensed","Roboto Condensed-fb",Helvetica,Arial,sans-serif;
        font-size: 1.5rem;
        color: #191919;
    }

}