.error-404-non-branded {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;

    .error-contents {
        margin: 84px auto 0;
        
        .image-404 {
            width: 318px;
            margin-left: -20px;

            .cls-1 {
                fill: #3ba6cc;
            }

            .cls-2 {
                fill: #3eb8eb;
            }

            .cls-3 {
                fill: #c3d4d8;
            }

            .cls-4 {
                fill: #e1ebef;
            }

            .cls-5 {
                fill: #37b7ea;
            }

            .cls-6 {
                fill: #a2b7bc;
            }

            .cls-7 {
                fill: #a9a9a9;
            }

            .cls-8 {
                fill: #9c9b9b;
            }

            .cls-9 {
                fill: #f197be;
            }

            .cls-10 {
                fill: #565656;
            }

            .cls-11 {
                fill: #fff;
            }

            .cls-12 {
                fill: #e9eff2;
            }

            .error-code {
                fill: #fff;
                font-family: HelveticaNeueW01-45Ligh;
                font-size: 80px;
            }
        }

        .not-found {
            margin-top: 62px;
            color: #3eb8ea;
            font-size: 66px;
            font-family: HelveticaNeueW01-45Ligh;
            letter-spacing: 1px;
            line-height: 90px;
        }

        .description {
            margin-top: 19px;
            font-family: HelveticaNeueW01-45Ligh;
            letter-spacing: 1px;
            font-size: 20px;
            line-height: 39px;
            color: #184a56;
        }

        .button {
            margin-top: 37px;
            outline: 0;
            height: 56px;
            letter-spacing: .8px;
            line-height: 50px;
            font-size: 22px;
            text-align: center;
            border-radius: 28px;
            font-family: HelveticaNeueW01-45Ligh;
            cursor: pointer;
            border: 2px solid #3eb8ea;
            background-color: #fff;
            color: #3eb8ea;
            box-sizing: border-box;
            transition-property: color,background-color;
            transition-duration: .2s;
            padding: 0 20px;
        }

        &.mobile {
            text-align: center;
            margin-bottom: 3rem;
        }
    }
}